jQuery(document).ready(function () {
  if ( jQuery('.swiper-home').length ) {
  var swipernews = new Swiper('.swiper-home', {
    /*initialSlide: 2,*/
    loop: true,
    slidesPerView: 1,
    /*centeredSlides: true,*/
    keyboardControl: true,
    mousewheelControl: true,
    paginationClickable: true,
    grabCursor: true,
    lazyLoading: true,
    preventClicks: false,
    preventClicksPropagation: false,
    lazyLoadingInPrevNext: true,
    spaceBetween: 15,
    navigation: {
      nextEl: ".home-next",
      prevEl: ".home-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        centeredSlides: false
      },

      800: {
        slidesPerView: 2,
        centeredSlides: false
      },

      1200: {
        slidesPerView: 3
      }
    },
    /*
    on: {
      slideChange: function () {
        alert(this.realIndex);
        const index_currentSlide = this.realIndex;
        const currentSlide = this.slides[index_currentSlide]
        this.slides[this.realIndex + 1].addClass="active2";


      },
    },*/
  });

  }
});